<template>
  <div>
    <v-row justify="center">
      <v-col>
        <v-row>
          <p>{{ description }}</p>
        </v-row>
        <v-row>
          <img :src="mutableValue.svg" />
        </v-row>
        <v-row justify="center">
          <p>
            <b v-if="mutableValue.signee !== '' && mutableValue.signee">
              {{renderDate(mutableValue.date)}},
              {{ mutableValue.signee }}
            </b>
          </p>
        </v-row>
        <v-row>
          <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <template #activator="{ on }">
              <v-row justify="center">
                <v-btn
                  v-if="mutableValue.svg"
                  :color="validationError ? 'error' : 'primary'"
                  :disabled="disabled"
                  v-on="on"
                >{{ $t('fields.signature.replace') }}
                </v-btn>
                <v-btn
                  v-else
                  :color="validationError ? 'error' : 'primary'"
                  :disabled="disabled"
                  v-on="on"
                >{{ $t('fields.signature.add') }}
                </v-btn>
              </v-row>
            </template>
            <v-card>
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ $t('fields.signature.signature') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn dark text @click="save($event)"
                  >{{ $t('fields.signature.add') }}
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-list>
                <v-alert
                  v-if="mutableValue.signee"
                  class="ma-2"
                  type="warning"
                  elevation="0"
                  dense
                >
                  Sie ersetzen die Signatur von <b>{{ mutableValue.signee }}</b> vom
                  {{new Intl.DateTimeFormat('de-DE', { dateStyle: 'full', timeStyle: undefined }).format(new Date(mutableValue.date))}},
                </v-alert>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-content>{{ description }}</v-list-item-content>
                    <v-list-item-group>

                    </v-list-item-group>
                    <v-list-item-subtitle>
                      {{ $t('fields.signature.signedBy') }}
                      <v-chip-group>
                        <v-chip pill>
                          <v-avatar left>
                            <v-img :src="$userController.generateProfilePicture(user.id)" alt="user-picture"></v-img>
                          </v-avatar>
                          {{ user.name }}
                        </v-chip>

                        <!--
                        <v-chip disabled pill>
                          <v-icon left>mdi-account-edit</v-icon>
                          {{ $t('actions.change') }}
                        </v-chip>
                        -->
                      </v-chip-group>

                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="justify-center">
                    <vueSignature
                      ref="signature"
                      :clear-on-resize="true"
                      :sig-option="option"
                      :w="'600px'"
                      :h="'300px'"
                    />
                </v-list-item>
                <v-list-item class="justify-center">
                  Signiert am {{new Intl.DateTimeFormat('de-DE', { dateStyle: 'full', timeStyle: undefined }).format(new Date())}}
                </v-list-item>
                <v-list-item>
                  <v-row justify="center">
                    <v-row justify="center">
                      <v-btn class="mx-1" color="primary" @click="clear">
                        <v-icon>mdi-trash-can</v-icon>
                        {{ $t('fields.signature.clear') }}
                      </v-btn>
                    </v-row>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/utils/LoadingSpinner";
import vueSignature from "vue-signature";


export default {
  name: "Signature",
  components: {LoadingSpinner, vueSignature},
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    description: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validationError: {
      type: String,
      default: null
    },
    docId: {
      type: String,
      required: true
    },
    field: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(221,221,221)"
      },
      dialog: false,
      temporalSignee: "",
      svg: null,
      user: null
    };
  },
  computed: {
    mutableValue: function () {
      if(!this.value)
        return {}
      else
        return this.value;
    }
  },
  methods: {
    save() {
      this.mutableValue.svg = this.$refs.signature.save("image/svg+xml");
      this.mutableValue.signee = this.user.name;
      this.mutableValue.userId = this.user.id;
      this.mutableValue.date = new Date().toISOString()

      this.$emit("change", this.mutableValue);
      this.temporalSignee = "";
      this.dialog = false;
    },
    clear() {
      this.$refs.signature.clear();
    },
    async getUser() {
      this.user = await this.$userController.getCurrentUserLocally()
    },
    renderDate(date) {
      try {
        return new Intl.DateTimeFormat('de-DE', { dateStyle: 'full', timeStyle: undefined }).format(new Date(date))
      } catch (exception) {
        return ""
      }
    }
  },
  mounted() {
    this.getUser()
  }
};
</script>

<style scoped></style>
